import React, { useEffect, useState } from "react";

const DailyHours = (props) => {
  const [today, setToday] = useState("");
  function getWeekDay(date) {
    let day = date.getDay();
    return [weekdays.en[day], weekdays.es[day]];
  }
  useEffect(() => {
    let weekDay = getWeekDay(now);
    setToday(props.lang === "es" ? weekDay[1] : weekDay[0]);
  }, []);

  let weekdays = {
    en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    es: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"]
  };

  let now = new Date();

  return (
    <>
      {props.AllClosed ? (
        <p className="text-danger">Closed</p>
      ) : (
        <>
          {weekdays.en.map(function (day, i) {
            return (
              <div key={i} className="d-flex justify-content-between mb-2">
                <div className={`${today === weekdays[props.lang][i] ? "font-weight-bold" : ""} mr-2`}>
                  {weekdays[props.lang][i]}
                </div>
                <div className={`${today === weekdays[props.lang][i] ? "font-weight-bold" : ""}`}>{props[day]}</div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default DailyHours;

DailyHours.defaultProps = {
  AllClosed: false,
  lang: "en",
  Monday: "9 AM-5 PM",
  Tuesday: "9 AM-5 PM",
  Wednesday: "9 AM-5 PM",
  Thursday: "9 AM-5 PM",
  Friday: "9 AM-6 PM",
  Saturday: "Closed",
  Sunday: "Closed"
};
