import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BranchServices = (props) => {
  const servicesData = {
    TellerServices: {
      en: "Teller Services",
      es: "Servicios de cajero"
    },
    SafeDepositBoxes: {
      en: "Safe Deposit Boxes",
      es: "Cajas de seguridad"
    },
    WalkUpATM: {
      en: "Walk-up ATM",
      es: "Cajero automático"
    },
    DriveThroughATM: {
      en: "Drive-through ATM",
      es: "Subir cajero automático"
    },
    DriveThroughBanking: {
      en: "Drive-through Banking",
      es: "Banca de autoservicio"
    }
  };

  return (
    <>
      {props.twoColumns ? (
        <div className="row">
          <div className={props.colClass}>
            <ul className={`${props.listClass} fa-ul ml-4`}>
              {props.services.TellerServices && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.TellerServices[props.lang]}
                </li>
              )}
              {props.services.SafeDepositBoxes && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.SafeDepositBoxes[props.lang]}
                </li>
              )}
              {props.services.WalkUpATM && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.WalkUpATM[props.lang]}
                </li>
              )}
            </ul>
          </div>
          <div className={props.colClass}>
            <ul className={`${props.listClass} fa-ul ml-4`}>
              {props.services.DriveThroughATM && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughATM[props.lang]}
                </li>
              )}
              {props.services.DriveThroughBanking && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughBanking[props.lang]}
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <ul className={`${props.listClass} fa-ul ml-4`}>
              {props.services.TellerServices && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.TellerServices[props.lang]}
                </li>
              )}
              {props.services.SafeDepositBoxes && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.SafeDepositBoxes[props.lang]}
                </li>
              )}
              {props.services.WalkUpATM && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.WalkUpATM[props.lang]}
                </li>
              )}
              {props.services.DriveThroughATM && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughATM[props.lang]}
                </li>
              )}
              {props.services.DriveThroughBanking && (
                <li className={props.itemClass}>
                  <span className="fa-li">
                    <FontAwesomeIcon className="text-success " icon={["fas", "check-circle"]} />
                  </span>
                  {servicesData.DriveThroughBanking[props.lang]}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
export default BranchServices;

BranchServices.defaultProps = {
  lang: "en",
  listClass: "",
  itemClass: "",
  twoColumns: false,
  colClass: "col-sm-12 col-lg-6",
  services: {
    TellerServices: true,
    WalkUpATM: true,
    DriveThroughATM: false,
    DriveThroughBanking: false,
    SafeDepositBoxes: false
  }
};
